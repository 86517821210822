<template>
  <div class="">
    <header-section
      :subtitle="promo.subtitle"
      :title="promo.title"
    />
    <contacts-section />
  </div>
</template>

<script>
import HeaderSection from '@/components/HeaderSection.vue';
import ContactsSection from '@/components/ContactsSection.vue';

export default {
  name: 'PageNotFound',
  components: {
    HeaderSection,
    ContactsSection,
  },
  data() {
    return {
      promo: {
        subtitle: '404',
        title: 'Page Not Found',
      },
    };
  },
};
</script>
